import { useApp } from "../../contexts/AppContext";
import { useTranslate } from "../../contexts/TranslateContext";
import { formatTime } from "../../libs";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import { ChatIcon } from "../ui/icon/chat-icon";
import { WhatsAppIcon } from "../ui/icon/whatsapp-icon";
import { ZaloIcon } from "../ui/icon/zalo-icon";
import { ChatNotifiIcon } from "../ui/icon/chat-notifi-icon";
import { MessengerIcon } from "../ui/icon/messenger-icon";

const ChannelComponent = ({
  chatbot,
  handleMessages,
  setFlow,
  setNewChat,
  setPrompt,
  messages,
}: any) => {
  const { translations } = useTranslate();
  const waNumber = chatbot?.whatsappNumber
    ?.replace(/[+\s]/g, "")
    ?.replace(/\s+/g, "");
  const {notifi, setIschat } = useApp();
  let message = messages?.length > 0 ? messages[0] : [];
  useEffect(() => {
    setIschat(false);
  }, []);

  return (
    <div className="w-full h-full bg-white  text-[#272727] py-3 px-4 rounded-[20px] flex items-center justify-between">
      <div className="w-1/2">
        <p className="font-semibold text-[16px]">
          {translations?.recent_messages}
        </p>
        <p className="text-[13px] flex items-center justify-between">
          <span>{message.name}</span>{" "}
          <span className="text-gray-400">
            {message?.updatedAt && formatTime(message?.updatedAt)}
          </span>
        </p>
      </div>
      <div className="flex items-center gap-3">
        <div
          onClick={() => {
            handleMessages();
            setFlow(undefined);
            setPrompt(undefined);
            setNewChat(true);
          }}
          className=""
        >
          {notifi ? (
            <ChatNotifiIcon
              style={{ color: chatbot?.chatbotColor || "#3047EC", height: 28, width: 28 }}
              className="w-[24px] cursor-pointer"
            />
          ) : (
            <ChatIcon
              style={{ color: chatbot?.chatbotColor || "#3047EC", height: 28, width: 28 }}
              className="w-[24px] cursor-pointer"
            />
          )}
        </div>

        {chatbot?.whatsappNumber && (
          <a
            href={
              isMobile
                ? `https://api.whatsapp.com/send/?phone=${waNumber}&text&type=phone_number&app_absent=0`
                : `https://web.whatsapp.com/send?phone=${waNumber}&text=hi`
            }
            target="_blank"
          >
            <WhatsAppIcon className="w-[24px] cursor-pointer" style={{height: 24, width: 24}}/>
          </a>
        )}

        {chatbot?.pageId && (
          <a
            href={`https://www.messenger.com/t/${chatbot.pageId}`}
            target="_blank"
          >
            <MessengerIcon className="w-[22px] cursor-pointer" style={{height: 24, width: 24}}/>
          </a>
        )}

        {chatbot?.oaId && (
          <a href={`http://zalo.me/${chatbot?.oaId}`} target="_blank">
            <ZaloIcon className="w-[24px] cursor-pointer" style={{height: 28, width: 28}}/>
          </a>
        )}
      </div>
    </div>
  );
};
export default ChannelComponent;
