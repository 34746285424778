import { useState, useEffect, useRef } from "react";
import "./App.css";
import { useSearchParams } from "react-router-dom";
import Chatbot from "./components/Chatbot";
import { accessToken, appURL, isAppDomain } from "./libs";
import { useApp } from "./contexts/AppContext";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { LanguageCode, validLanguages } from "./libs/translations";
import "./components/tailwind.css";
import { useTranslate } from "./contexts/TranslateContext";
import Assistant from "./components/Assistant";
import Chat from "./components/Chat";
import { useWorkflow } from "./contexts/WorkflowContext";
import { useShoppingContext } from "./contexts/ShoppingContext";
import { generateString } from "./libs";
import { ChatbotType } from "@/types/chatbot";

const taggoai = document.getElementById("taggoai");
axios.defaults.headers.common["x-api-key"] = accessToken;
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`;

interface ChatbotWrapperProps {
  botId: string;
  dataBot: ChatbotType;
  flows: any[];
  uuid: string;
  lead: any;
  setLead: (lead: any) => void;
  setShow: (show: boolean) => void;
  start: string;
  isIframe: boolean;
}

const ChatbotWrapper: React.FC<ChatbotWrapperProps> = ({ 
  botId, 
  dataBot, 
  flows, 
  uuid, 
  lead, 
  setLead, 
  setShow, 
  start, 
  isIframe 
}) => {
  const chatRef = useRef<any>();
  
  return (
    <Chatbot
      botId={botId}
      dataBot={dataBot}
      flows={flows}
      uuid={uuid}
      lead={lead}
      setLead={setLead}
      setShow={setShow}
      start={start}
      isIframe={isIframe}
      createMessage={(isAgent: boolean, type: string, prompt: string) => chatRef.current?.createMessage(isAgent, type, prompt)}
      reload={() => chatRef.current?.reload()}
      ref={chatRef}
    />
  );
};

function App() {
  const taggoaiBotId = taggoai?.getAttribute("bot-id");
  let [searchParams] = useSearchParams();
  let botId = searchParams.get("botId") || taggoaiBotId;
  let shopId = searchParams.get("shopId");
  let platform = searchParams.get("platform");
  let isIframe = searchParams.get("isIframe");
  let isButton = searchParams.get("isButton");
  let start = searchParams.get("start");
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [lead, setLead] = useState();
  const [show, setShow] = useState(isAppDomain || isIframe || false);
  const { setBot, setUuid } = useApp();
  const { changeLanguage, language } = useTranslate();

  let url = `${appURL}/api/chatbot/${botId}/detail`;
  const params = new URLSearchParams();
  const userLanguage: string = navigator.language;

  if (shopId) {
    params.append("shop_id", shopId);
  }

  if (platform) {
    params.append("platform", platform);
  }
  const locale = userLanguage ? userLanguage?.split("-")[0] : "en";
  const queryString = params.toString();
  if (queryString) {
    url += `?${queryString}&language=${locale}`;
  } else {
    url += `?language=${locale}`;
  }

  useEffect(() => {
    fetchData();
  }, [botId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(url);
      const result = await response.data;
      setData(result);
      setLead(result.lead);
      const extractedLanguage = userLanguage?.split("-")[0] || 'en';

      // Check if the extracted language is a valid LanguageCode, if not, fallback to 'en'
      const language: LanguageCode = validLanguages.includes(extractedLanguage as LanguageCode)
        ? (extractedLanguage as LanguageCode)
        : 'en';

      changeLanguage(result?.chatbot?.language || language); //user language
      setUuid(result?.uuid);
      setBot({
        ...result?.chatbot,
        links: result?.links,
      });
    } catch (error) {
      //console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const syncInit = () => {
      document.title = data?.chatbot?.name || "Taggo: AI Chatbot";
      return () => (document.title = data?.chatbot?.name || "Taggo: AI Chatbot");
    };
    syncInit();
  }, [data]);

  if (loading) {
    return (
      <div className="first-loading">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      {/* <button className="fixed w-[46px] h-[46px] bottom-0 right-0" onClick={() => setShow(!show)}>
        {show ? <img width={48} height={48} src="/chatClose.svg" /> : <img width={48} height={48} src="/chatOpen.svg" />}
      </button> */}
      <div className={`${isAppDomain ? "flex h-[100vh] items-center" : ""}`}>
        <div className={`${isMobile ? "mobile" : "app"} flex h-[100vh] items-center`}>
          {data.chatbot?.type === "research" ?
          <Assistant
            botId={data.chatbot?._id}
            dataBot={data.chatbot}
            flows={(data?.flows && data?.flows.length > 0) ? data?.flows[0] : []}
            uuid={data.uuid}
            lead={lead}
            setLead={setLead}
            setShow={setShow}
            start={"init"}
            isIframe={!!isIframe}
          />:
          <ChatbotWrapper
            botId={data.chatbot?._id}
            dataBot={data.chatbot}
            flows={(data?.flows && data?.flows.length > 0) ? data?.flows[0] : []}
            uuid={data.uuid}
            lead={lead}
            setLead={setLead}
            setShow={setShow}
            start={start ?? "home"}
            isIframe={!!isIframe || !!shopId}
          />}
        </div>
      </div>
    </>
  );
}

export default App;
