import { useWorkflow } from '../../contexts/WorkflowContext';
import { ChevronForwardIcon } from '../ui/icon/chevron-forward-icon';
import { findCurrentFlow, findFlowByNextStep } from '../../utils/workflow'
import { IAction, IFlow } from '../../types/workflow';
import { generateString } from '../../libs';
import { Message } from 'ai';
import { Dispatch, SetStateAction } from 'react';

interface FlowComponentProps {
  buttons: any[];
  flows: IFlow[];
  setFlow: (flow: IFlow | undefined) => void;
  setNewChat: (value: boolean) => void;
  setPrompt: (value: string | undefined) => void;
  setMessages: Dispatch<SetStateAction<Message[]>>;
  createMessage: (isAgent: boolean, type: string, prompt: string) => void;
  onReload: () => void;
  title: string;
}

const FlowComponent = ({
  buttons,
  flows,
  setFlow,
  setNewChat,
  setPrompt,
  setMessages,
  createMessage,
  onReload,
  title,
}: FlowComponentProps) => {
  const { state: { history }, dispatch } = useWorkflow();

  const handleAction = async (button: any) => {
    const [message, nextStep] = button.payload.split(':');

    // Handle next step if exists
    if (nextStep && nextStep !== 'none') {
      const nextFlow = findFlowByNextStep(flows, nextStep);
      if (nextFlow) {
        setFlow(nextFlow);
        dispatch({ type: 'SET_CURRENT_FLOW', payload: nextFlow });
      }
    } else {
      // Add message to chat
      // setMessages((prevMessages: Message[]) => [
      //   ...prevMessages,
      //   {
      //     role: "user",
      //     content: message,
      //     id: generateString(8),
      //   } as Message,
      // ]);

      // // Create message and reload
      // createMessage(true, message, "user");
      // onReload();
      setPrompt(message)
      //setFlow(undefined);
    }

    dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' });
    setNewChat(false);
  }

  return (
    <div className="w-full h-auto max-h-full cursor-pointer bg-white  text-[#272727] py-3 px-4 rounded-[20px]">
      <div
        id="stylescroll"
        className="w-full h-full max-h-[200px] max-md:max-h-[220px] overflow-y-auto "
      >
        <p className="font-semibold text-[18px] text-[#272727] mb-2">{title}</p>
        {buttons.map((item: any, idx: number) => (
          <div
            onClick={() => {
              handleAction(item);
            }}
            key={idx}
            className="text-[#272727] flex justify-between gap-3 items-center pr-3 "
          >
            <p className="text-[#272727] font-medium text-[16px] py-2 ">
              {item.title}
            </p>
            <ChevronForwardIcon className="w-[18px] text-gray-500" style={{}} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlowComponent;
