import { useEffect, useRef } from "react";
import ChatItem from "./chat-item";
import ChatAvatar from "./chat-avatar";
import Markdown from "react-markdown";
import { Button } from "../ui/element/button";
import { useApp } from "../../contexts/AppContext";
import { replaceName } from "../../libs";
import { useTranslate } from "../../contexts/TranslateContext";
import { useWorkflow } from "../../contexts/WorkflowContext";
import { Message } from "ai/react";
import { isMobile } from "react-device-detect";
import { AIThinkingIndicator } from "../ui/thinking-indicator";

interface ChatMessagesProps {
  messages: any[];
  reload: () => void;
  dataBot: any;
  setInput: (input: string) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  createMessage: (
    isAgent: boolean,
    prompt: string,
    type: string
  ) => void;
  setIsAgent: (isAgent: boolean) => void;
  sendAgent: () => void;
  lead: any;
  setMessages: (messages: any[]) => void;
  baseMessages: Message[];
  append: any;
  closeMess: any;
  setCloseMess: any;
  uuid: string;
  flows: any[];
  setFlow: (flow: any) => void;
  flow: any;
  messResult: any;
  setMessResult: (messResult: any) => void;
  isAgent: boolean;
  newchat: any;
  newFlow: any;
  isLoading: boolean;
}

const renderers = {
  a: (props: any) => {
    let { href, children } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  },
};

export function ChatMessages({
  messages,
  reload,
  dataBot,
  setInput,
  handleSubmit,
  createMessage,
  setIsAgent,
  sendAgent,
  lead,
  setMessages,
  baseMessages,
  isLoading,
}: ChatMessagesProps) {
  const { page, chatbot } = useApp();
  const { translations } = useTranslate();
  const { dispatch } = useWorkflow()
  const scrollableChatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableChatContainerRef.current) {
      scrollableChatContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length, messages]);

  const handleClick = (prompt: string, isAgent: boolean) => {
    setIsAgent(isAgent);
    setInput(prompt);
    createMessage(true, prompt, "user");
    dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' })
    if (isAgent) {
      sendAgent();
    }
  };

  return (
    <div
      className={`w-full h-[85%] max-md:max-w-[100vw]  max-w-5xl pb-3 ${isMobile && "h-[85%]"
        }`}
    >

      <div id="stylescroll" className="h-full overflow-auto">
        <div className="flex items-center gap-3 py-3 pe-3">
          <ChatAvatar role="user" />
          <Markdown className="break-words" components={renderers}>
            {replaceName(
              dataBot?.welcomeMessage ??
              (`${translations?.hello_how_can_i_help}` ||
                "Hello @name, 👋 I'm your AI Assistant. How can I help you?"),
              lead?.name || ""
            )}
          </Markdown>
        </div>

        {dataBot?.prompts && page == "init" && (
          <div className="mb-3 ">
            {dataBot.prompts.map((item: any, idx: number) => (
              <form
                key={idx}
                onSubmit={handleSubmit}
                style={{
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
                className="flex items-start gap-4 py-1 justify-end"
              >
                <Button
                  onClick={() =>
                    handleClick(
                      item?.text && item?.text !== ""
                        ? item.text
                        : item?.prompt,
                      false
                    )
                  }
                  label={item?.prompt}
                />
              </form>
            ))}
          </div>
        )}

        <div className={`flex flex-col gap-3`}>
          {messages.map((m: any) => (
            <ChatItem
              key={m.id}
              message={m}
              messages={baseMessages}
              setMessages={setMessages}
              createMessage={createMessage}
              reload={reload}
              isLoading={isLoading}
            />
          ))}
          {(isLoading) && (
            <div className="flex items-start items-center pl-2">
              <div className="h-6 w-6 flex items-center justify-center text-white shrink-0">
                <ChatAvatar role='bot' />
              </div>
              <div className="bg-white p-3 rounded-lg">
                <AIThinkingIndicator />
              </div>
            </div>
          )}
        </div>

        <div ref={scrollableChatContainerRef} />
      </div>

    </div>
  );
}