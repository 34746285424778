import { ChatbotType } from "@/types/chatbot";
import { generateString } from "../../../libs";
import { Button } from "../../ui/element/button";
import { isMobile } from "react-device-detect";
import { Message } from "ai";
import { Dispatch, SetStateAction } from "react";

interface SuggestedToolsProps {
  suggested: { data: string[] };
  setMessages: Dispatch<SetStateAction<Message[]>>;
  createMessage: (flag: boolean, message: string, role: string) => void;
  reload: () => void;
  chatbot: ChatbotType;
}

export const SuggestedTool: React.FC<SuggestedToolsProps> = ({
  suggested,
  setMessages,
  createMessage,
  reload,
}) => {
  const handleCreate = (message: string) => {
    setMessages((prevMessages: Message[]) => [
      ...prevMessages,
      {
        role: "user",
        content: message,
        id: generateString(8),
      } as Message,
    ]);
    
    createMessage(true, message, "user");
    reload();
  };

  return (
    <div className={`flex justify-end m-2 text-sm p-2`}>
      <div>
        {suggested?.data.map((text: string, index: number) => (
          <div key={index} className={`mb-2 flex justify-end`}>
            <Button onClick={() => handleCreate(text)} label={text} />
          </div>
        ))}
      </div>
    </div>
  );
};
