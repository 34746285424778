import React from 'react'
import { Button } from "../../../ui/button"
import { useShoppingContext } from "../../../../contexts/ShoppingContext"
import type { Product } from "../../../../types/product"
import { AddIcon } from '../../../ui/icon/add-icon';
import { useTranslate } from '../../../../contexts/TranslateContext';

interface AddToCartProps {
  product: Product;
}

export function AddToCart({ product }: AddToCartProps) {
  const { dispatch } = useShoppingContext()
  const {translations} = useTranslate();
  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch({ type: 'ADD_TO_CART', payload: product })
  }

  return (
    <Button 
      size="sm" 
      variant="ghost" 
      className="w-full text-[#4263EB] hover:text-[#4263EB] hover:bg-[#EEF1FF] p-0 h-8"
      onClick={handleAddToCart}
    >
      <AddIcon className="h-4 w-4 mr-1" style={{color: '#333'}}/>
      {translations.addToCart}
    </Button>
  )
}

