import { useTranslate } from '../../contexts/TranslateContext';
import { useWorkflow } from "../../contexts/WorkflowContext";
import { ChevronForwardIcon } from "../ui/icon/chevron-forward-icon";

const PromptComponent = ({
  chatbot,
  setNewChat,
  setPrompt,
  setFlow,
  setMessages,
  onReload,
  createMessage,
}: any) => {
  const { translations } = useTranslate();
  const { dispatch } = useWorkflow();

  const handleClick = (item: any) => {
    let prompt;
    if (item.text) {
      prompt = item.text;
    } else {
      prompt = item.prompt;
    }
    setPrompt(prompt)
    //setMessages([{ role: 'user', content: item.prompt, id: Date.now().toString() }]);
    //onReload?.();
    //createMessage(true, prompt, "user");
    dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' });
    setNewChat(true);
    setFlow(null);
  }

  return (
    <div className="w-full h-auto max-h-full cursor-pointer bg-white  text-[#272727] py-3 px-4 rounded-[20px]">
      <div
        id="stylescroll"
        className="w-full h-full max-h-[200px] max-md:max-h-[220px] overflow-y-auto pb-3"
      >
        <p className="font-semibold text-[18px] text-[#272727]">
          {translations?.find_for_help}
        </p>
        {chatbot &&
          chatbot?.prompts.length > 0 &&
          chatbot?.prompts.map((item: any, idx: number) => (
            <div
              key={idx}
              onClick={() => handleClick(item)}
              className="text-[666666] flex justify-between gap-3 items-center pr-3 "
            >
              <p className="text-[666666] font-medium text-[16px] py-[7px]">
                {item.prompt}
              </p>
              <ChevronForwardIcon className="w-[18px] text-gray-400" style={{}} />
            </div>
          ))}
      </div>
    </div>
  );
};
export default PromptComponent;
