import { Hotel } from "../../../../types/hotel";
import { useShoppingContext } from "../../../../contexts/ShoppingContext";
import { useApp } from "../../../../contexts/AppContext";
import { useEffect, useState } from "react";
import { checkImageExists, cleanPrice, formatPrice } from "../../../../libs";
import ChatAvatar from "../../chat-avatar";
import { Badge } from "../../../../components/ui/badge";
import { StarIcon } from "../../../ui/icon/star-icon";
import { Button } from "../../../../components/ui/button";

export function HotelCard({
    hotels,
}: {
    hotels: Hotel[];
}) {
    const { state: { cartItems }, dispatch } = useShoppingContext()
    const { chatbot } = useApp()
    const [validImages, setValidImages] = useState<Record<number, boolean>>({});

    useEffect(() => {
        const validateImages = async () => {
            const validations: Record<number, boolean> = {};
            await Promise.all(
                hotels?.map(async (hotel: Hotel, index: number) => {
                    validations[index] = await checkImageExists(hotel.image_url || "") as boolean;
                })
            );
            setValidImages(validations);
        };
        if (hotels?.length > 0) {
            validateImages();
        }
    }, [hotels]);

    const handleHotelClick = (hotel: Hotel) => {

    };

    return (
        <div className={`flex items-start gap-2 justify-start mb-3`}>
            <div className="space-y-6 order-1">
        {hotels?.map((hotel, index) => (
          <div key={hotel.id || index} className="overflow-hidden hover:shadow-md transition-shadow me-2 bg-gray-100 rounded-md">
            <div className="flex flex-col sm:flex-row">
            {validImages[index] && hotel.image_url && (  <div className="relative h-48 sm:h-auto sm:w-1/3">
                        <img
                            src={hotel.image_url}
                            alt={hotel.hotel_name}
                            className="object-cover"
                            sizes="(max-width: 640px) 100vw, 33vw"
                        />
              </div>
              )}
              <div className="flex-1 p-4">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <h3 className="font-semibold text-lg mb-1">{hotel.hotel_name}</h3>
                    <div className="flex items-center gap-1">
                      <StarIcon className="h-4 w-4 fill-primary text-primary" style={{}}/>
                      <span className="text-sm text-muted-foreground">
                        {hotel.star} ({hotel.rates} reviews)
                      </span>
                    </div>
                  </div>
                  <Badge variant="secondary" className="text-lg">
                    {formatPrice(cleanPrice(hotel.price))} {chatbot.currency}
                    <span className="text-xs ml-1">/night</span>
                  </Badge>
                </div>
                {hotel.description && (
                  <p className="text-sm text-muted-foreground line-clamp-2 mt-2">
                    {hotel.description}
                  </p>
                )}
                {hotel.amenities && (
                  <div className="flex gap-2 mt-3 flex-wrap">
                    {hotel.amenities.split(',').map((amenity, i) => (
                      <Badge key={i} variant="outline" className="text-xs">
                        {amenity.trim()}
                      </Badge>
                    ))}
                  </div>
                )}
                <div className="mt-2 flex justify-end">
                  <a style={{
                    background: chatbot.chatbotColor
                  }} className="px-2 py-1 text-white rounded-md" href={hotel.booking_url} target="_blank">
                     Book now
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
            <ChatAvatar role="bot" />
        </div>
    )
}