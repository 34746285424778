import { Button } from "../../..//ui/button"
import type { Product } from "../../../../types/product"
import { useShoppingContext } from "../../../../contexts/ShoppingContext";
import { useApp } from "../../../../contexts/AppContext";
import { CloseIcon } from "../../../ui/icon/close-icon";
import { AddToCart } from "./add-to-cart";
import { useEffect, useState } from "react";
import { checkImageExists, cleanPrice, formatPrice } from "../../../../libs";
import { useTranslate } from "../../../../contexts/TranslateContext";

interface ProductDetailProps {
  product: Product;
}

export function ProductDetail({ product }: ProductDetailProps) {
  const { state: { cartItems }, dispatch } = useShoppingContext()
  const { chatbot } = useApp();
  const {translations} = useTranslate();
  const [validImage, setValidImage] = useState<boolean>(true);

  useEffect(() => {
    const validateImages = async () => {
      const validation: boolean = await checkImageExists(product.image_url || "") as boolean;
      setValidImage(validation);
    };
    
    validateImages();
  }, [product]);

  const handleProductClick = (product: Product) => {
    dispatch({
      type: 'SET_PRODUCT', payload: {
        id: '',
        product_name: '',
        description: '',
        price: '',
        image_url: '',
        product_url: ''
      }
    })
  };
  return (
    <div className="flex flex-col h-full w-full overflow-y-auto">
      <div className="flex justify-end px-2">
        <Button
          variant="ghost"
          size="icon"
          className="text-white hover:bg-[#3651c9]"
          onClick={() => {
            handleProductClick(product);
          }}
        >
          <CloseIcon className="h-6 w-6 mr-1" style={{ color: '#333' }} />
        </Button>
      </div>
      <div className="flex-grow overflow-y-auto">
        <div className="bg-gray-50 pb-4">
          {(product.image_url && validImage) && <img
            src={product.image_url}
            alt={product.product_name}
            className="w-full h-64 object-cover rounded-lg"
          />}
        </div>

        <div className="space-y-4 px-4">
          <div className="flex justify-between items-start pt-4">
            <div>
              <h2 className="text-xl font-bold">{product.product_name}</h2>
            </div>
            {product.price && <span className="text-xl font-bold">{formatPrice(cleanPrice(product.price))} {chatbot.currency}</span>}
          </div>

          <p className="text-gray-600">
            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
            />
          </p>
        </div>
      </div>

      <div className="sticky bottom-0 bg-gray-50 p-4 pb-0">
        {chatbot.isEcommerce && <AddToCart product={product} />}
      </div>
    </div>
  )
}

