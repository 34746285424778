import React, { useEffect, useState } from 'react'
import { Button } from "../../../ui/button"
import { Input } from "../../../ui/input"
import { Label } from "../../../ui/label"
import { useShoppingContext } from "../../../../contexts/ShoppingContext"
import { useApp } from '../../../../contexts/AppContext'
import axios from 'axios'
import { accessToken, appURL, checkImageExists, cleanPrice, formatPrice } from '../../../../libs'
import { CloseIcon } from '../../../ui/icon/close-icon'
import { useTranslate } from '../../../../contexts/TranslateContext'
import { Product } from '../../../../types/product'

interface ShoppingCartProps {
  onCheckout: (formData: FormData) => void
}

export function ShoppingCart({ onCheckout }: ShoppingCartProps) {
  const [isCheckout, setIsCheckout] = React.useState(false)
  const { state: { cartItems }, dispatch } = useShoppingContext();
  const { uuid, chatbot } = useApp();
  const { translations } = useTranslate();
  const total = cartItems.reduce((sum, item) => sum + cleanPrice(item.price) * item.quantity, 0)

  const onRemove = (id: string) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: id })
  }

  const handleCheckout = (formData: FormData) => {
    axios.post(`${appURL}/api/order`, {
      botId: chatbot._id,
      uuid: uuid,
      name: formData.get('fullName'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      address: formData.get('address'),
      items: cartItems.map(item => ({
        ...item,
        name: item.product_name,
        product_name: undefined,
        price: item.price ? cleanPrice(item.price) : 0,
      })),
      currency: chatbot.currency || 'USD',
      total,
      shopUrl: window.location.href
    }, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': accessToken
      }
    })
    dispatch({ type: 'CLEAR_CART' })
    onCheckout(formData)
    setIsCheckout(false)
  }

  const [validImages, setValidImages] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const validateImages = async () => {
      const validations: Record<number, boolean> = {};
      await Promise.all(
        cartItems?.map(async (product: Product, index: number) => {
          validations[index] = await checkImageExists(product.image_url || "") as boolean;
        })
      );
      setValidImages(validations);
    };
    if (cartItems?.length > 0) {
      validateImages();
    }
  }, [cartItems]);

  return (

      <div className="h-full flex flex-col">
      <div className="flex-grow overflow-y-auto">
        {cartItems.length === 0 ? (
          <p className="text-gray-500">{translations.shopping_cart}</p>
        ) : (
          <>
            {!isCheckout && cartItems.map((item, index) => (
              <div key={item.id} className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  {validImages[index] && item.image_url && (<img src={item.image_url} alt={item.product_name} className="w-16 h-16 rounded mr-4" />)}
                  <div>
                    <p className="font-medium">{item.product_name}</p>
                    <p className="text-sm text-gray-500">{formatPrice(cleanPrice(item.price))} x {item.quantity}</p>
                  </div>
                </div>
                <Button
                  size="icon"
                  variant="ghost"
                  className="text-gray-500 hover:text-red-500"
                  onClick={() => onRemove(item.id)}
                >
                  <CloseIcon className="h-4 w-4 mr-1 hover:text-red-500" style={{ color: '#333' }} />
                </Button>
              </div>
            ))}
          </>
        )}
      </div>
      {cartItems.length > 0 && (
        <div className="mt-6 pt-4 border-t border-gray-200">
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold">{translations.total}:</span>
            <span className="font-semibold">{formatPrice(total)} {chatbot.currency}</span>
          </div>
          {!isCheckout ? (
            <Button className="w-full bg-gray-600 hover:bg-[#3651c9] text-white" onClick={() => setIsCheckout(true)}>
              {translations.checkout}
            </Button>
          ) : (
            <form onSubmit={(e) => {
              e.preventDefault()
              handleCheckout(new FormData(e.currentTarget))
            }}>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="fullName">{translations.full_name}</Label>
                  <Input id="fullName" name="fullName" required />
                </div>
                <div>
                  <Label htmlFor="email">{translations.email}</Label>
                  <Input id="email" name="email" type="email" required />
                </div>
                <div>
                  <Label htmlFor="phone">{translations.phone}</Label>
                  <Input id="phone" name="phone" type="tel" required />
                </div>
                <div>
                  <Label htmlFor="address">{translations.address}</Label>
                  <Input id="address" name="address" required />
                </div>
              </div>
              <Button type="submit" className={`w-full mt-6 bg-gray-600 hover:bg-[#3651c9] text-white`}>
                {translations.complete_order}
              </Button>
            </form>
          )}
        </div>
      )}
    </div>    
  )
}

